module.exports = {
  siteTitle: 'Your trusted React developer | React Freelancer Switzerland', // <title>
  siteDescription:
    'Looking for a React freelancer? Bridge high workloads by flexibly handing out work packages.',
  siteUrl: `https://react-freelancer.ch`,
  languageCode: 'en',
  siteName: 'React Freelancer Switzerland',
  manifestName: 'React Freelancer Switzerland',
  manifestShortName: 'React CH', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#8aa989',
  manifestThemeColor: '#8aa989',
  manifestDisplay: 'minimal-ui',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio

  // social
  socialLinks: [
    {
      icon: 'icon-social-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/florian-gyger/',
    },
    {
      icon: 'icon-social-github',
      name: 'GitHub',
      url: 'https://github.com/flogy',
    },
    {
      icon: 'icon-social-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/FlorianGyger',
    },
  ],
  email: 'info@floriangyger.ch',
  phone: '+41 44 586 42 12',
  address: 'Bachmattstrasse 15, 5210 Windisch, Switzerland',
};
