import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

import config from '../../config';

import florianGyger from '../assets/images/florian-gyger.jpg';

import satheeskumarUthayakumar from '../assets/images/testimonials/satheeskumar-uthayakumar.jpg';
import danielTerNedden from '../assets/images/testimonials/daniel-ter-nedden.jpg';
import remoHunziker from '../assets/images/testimonials/remo-hunziker.jpg';

import laguna from '../assets/images/customers/laguna.png';
import cultimedia from '../assets/images/customers/cultimedia.png';
import fahrziel from '../assets/images/customers/fahrziel.png';
import man from '../assets/images/customers/man.png';
import schuermatt from '../assets/images/customers/schuermatt.png';
import swisslog from '../assets/images/customers/swisslog.png';
import { useLatestBlogPosts } from '../../plugins/gatsby-theme-fgs-blog/src/hooks/use-blog-posts';
import BlogPostPreview from '../components/BlogPostPreview';

const IndexPage = () => {
  const latestBlogArticles = useLatestBlogPosts(4);

  return (
    <Layout hideNavbar={false}>
      <header className="masthead">
        <div className="background" />
        <div className="content d-flex">
          <div className="container text-center my-auto">
            <h1 className="mb-5 text-uppercase">
              Avoid stress <br />
              <span className="font-weight-normal">and </span>
              <br />
              meet deadlines
            </h1>
            <h2 className="text-primary mb-5">
              Get your trusted partner for your React development projects.
            </h2>
            <a
              className="btn btn-light btn-xl"
              href="mailto:florian.gyger@floriangyger.ch"
              title="Write an email to Florian Gyger"
            >
              Let's talk
            </a>
          </div>
        </div>
        <div className="overlay"></div>
      </header>

      <section
        className="content-section bg-primary text-white text-center"
        id="benefits"
      >
        <div className="container">
          <div className="content-section-heading">
            {/* <h3 className="text-secondary mb-0">Your benefits</h3> */}
            <h2 className="mb-5">Your benefits</h2>
          </div>
          <div className="row">
            <div className="col-md-4 mb-5 mb-lg-0">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-fire"></i>
              </span>
              <h4>
                <strong>Bypass peaks</strong>
              </h4>
              <p className="text-faded mb-0">
                Bridge high workloads by flexibly handing out work packages
              </p>
            </div>
            <div className="col-md-4 mb-5 mb-lg-0">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-graduation"></i>
              </span>
              <h4>
                <strong>Real life expertise</strong>
              </h4>
              <p className="text-faded mb-0">
                Benefit from know-how that is coming from real customer projects
              </p>
            </div>
            <div className="col-md-4 mb-5 mb-md-0">
              <span className="service-icon rounded-circle mx-auto mb-3">
                <i className="icon-eye"></i>
              </span>
              <h4>
                <strong>New perspectives</strong>
              </h4>
              <p className="text-faded mb-0">
                Get an external viewpoint and possibly uncover hidden
                bottlenecks
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section bg-light" id="callout">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="content-section-heading text-left">
                <h2 className="mb-5">Looking for a React freelancer?</h2>
              </div>
              {/* <p className="lead mb-5"></p> */}
              <div className="row d-flex align-items-center">
                <div className="col-md-9 text-justify text-md-left mb-5">
                  <p>
                    <span style={{ fontSize: '2rem' }}>Hi</span>, my name is
                    Florian Gyger and I'm a passionate software developer
                    located in Switzerland.
                  </p>
                  <p>
                    I help companies turning their app visions into reality -
                    either as full-service development or by freelancing. To
                    build fast web and mobile apps efficiently, I specialize in
                    using React and React Native together with Gatsby and
                    TypeScript.
                  </p>
                  <p>
                    Besides my professional work I also like contributing to
                    open source, sharing my knowledge {/*on conferences and */}
                    in my blog, as well as attending hackathons.
                  </p>
                </div>
                <div className="col-md-3 mb-5">
                  <img
                    style={{
                      width: 200,
                    }}
                    className="img-fluid rounded-circle"
                    src={florianGyger}
                    alt="Florian Gyger"
                  />
                </div>
              </div>
              <a
                className="btn btn-primary btn-xl"
                href="mailto:florian.gyger@floriangyger.ch"
                title="Write an email to Florian Gyger"
              >
                Let's talk
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className="content-section bg-primary text-white text-center"
        id="testimonials"
      >
        <div className="container">
          <div className="content-section-heading">
            {/* <h3 className="text-secondary mb-0">Your benefits</h3> */}
            <h2 className="mb-5">What my customers say</h2>
          </div>
          <div className="row mb-5">
            <div className="col-md-4 mb-5 mb-lg-0">
              <img
                className="testimonial-avatar img-fluid rounded-circle mx-auto mb-5"
                src={satheeskumarUthayakumar}
                alt="Satheeskumar Uthayakumar"
              />
              <h4 className="mb-0">
                <strong>Satheeskumar Uthayakumar</strong>
              </h4>
              <p className="text-faded mb-3">
                Head of Software Support at Swisslog
              </p>
              <p className="testimonial-quote mb-0">
                "We got to count on our reliable and technically skilled partner
                Florian Gyger in multiple software projects. Thanks to his
                solution-oriented way of working we were able to serve our
                customers in time despite the narrow project plan."
              </p>
            </div>
            <div className="col-md-4 mb-5 mb-lg-0">
              <img
                className="testimonial-avatar img-fluid rounded-circle mx-auto mb-5"
                src={danielTerNedden}
                alt="Daniel Ter-Nedden"
              />
              <h4 className="mb-0">
                <strong>Daniel Ter-Nedden</strong>
              </h4>
              <p className="text-faded mb-3">Owner of Cultimedia</p>
              <p className="testimonial-quote mb-0">
                "The collaboration with Florian Gyger was very pleasant. We
                reached our goal quickly, thanks to his good comprehension,
                clear communication and his great professional competence."
              </p>
            </div>
            <div className="col-md-4 mb-5 mb-md-0">
              <img
                className="testimonial-avatar img-fluid rounded-circle mx-auto mb-5"
                src={remoHunziker}
                alt="Remo Hunziker"
              />
              <h4 className="mb-0">
                <strong>Remo Hunziker</strong>
              </h4>
              <p className="text-faded mb-3">Owner of Fahrschule Fahrziel</p>
              <p className="testimonial-quote mb-0">
                "Florian Gyger put himself in my and even my customer's
                position. Like that, he was able to get a different view-point
                which led him to outperform my expectations by far."
              </p>
            </div>
          </div>

          <div className="row mb-5"></div>

          <div className="row d-flex align-items-end">
            <div className="col-4 col-md-2 mb-5 mb-lg-0">
              <img
                className="customer-logo img-fluid mx-auto mb-5"
                src={laguna}
                alt="Laguna Health"
              />
            </div>
            <div className="col-4 col-md-2 mb-5 mb-lg-0">
              <img
                className="customer-logo img-fluid mx-auto mb-5"
                src={swisslog}
                alt="Swisslog AG"
              />
            </div>
            <div className="col-4 col-md-2 mb-5 mb-lg-0">
              <img
                className="customer-logo img-fluid mx-auto mb-5"
                src={man}
                alt="MAN Energy Solutions Schweiz AG"
              />
            </div>
            <div className="col-4 col-md-2 mb-5 mb-lg-0">
              <img
                className="customer-logo img-fluid mx-auto mb-5"
                src={schuermatt}
                alt="Stiftung Schürmatt"
              />
            </div>
            <div className="col-4 col-md-2 mb-5 mb-lg-0">
              <img
                className="customer-logo img-fluid mx-auto mb-5"
                src={fahrziel}
                alt="Fahrziel"
              />
            </div>
            <div className="col-4 col-md-2 mb-5 mb-lg-0">
              <img
                className="customer-logo img-fluid mx-auto mb-5"
                src={cultimedia}
                alt="Cultimedia"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="content-section" id="blog">
        <div className="container text-center">
          <div className="content-section-heading text-center">
            <h2 className="mb-5">My latest blog articles</h2>
          </div>
          <div className="w-50 mx-auto mb-5">
            <p>
              Read about <b>React development</b>, the <b>Gatsby framework</b>{' '}
              and general <b>web development news</b> in my blog.
            </p>
          </div>
          <BlogPostPreview blogPosts={latestBlogArticles} />
          <Link
            className="btn btn-xl btn-primary mr-4"
            to="/blog"
            title="Blog articles overview"
          >
            View all articles
          </Link>
        </div>
      </section>

      <section className="content-section bg-primary text-white">
        <div className="container text-center">
          <div className="content-section-heading text-center">
            <h2 className="mb-4">Stay up to date</h2>
          </div>
          <div className="w-50 mx-auto mb-5">
            <p>
              If you like to be notified first about upcoming project releases,
              new blog posts and my work in general, feel free to join my email
              list.
            </p>
          </div>
          <Link
            className="btn btn-xl btn-light mr-4 mb-3"
            to="/mailing-list"
            title="Mailing list signup form"
          >
            Join email list
          </Link>
          <a
            className="btn btn-xl btn-dark mb-3"
            href="mailto:florian.gyger@floriangyger.ch"
            title="Write an email to Florian Gyger"
          >
            Let's talk directly
          </a>
        </div>
      </section>

      <section className="content-section pb-0" id="social">
        <div className="container text-center">
          <div className="content-section-heading text-center">
            <h2 className="mb-4">Connect on Social Media</h2>
          </div>
          <div className="w-50 mx-auto mb-5">
            <p>
              Besides all the coding I also love talking about innovative
              business ideas and topics like climate change, food and gardening.
            </p>
            <p>
              If you enjoy that too it would be awesome to connect on social
              media!
            </p>
          </div>
          <ul className="list-inline mb-5">
            {config.socialLinks.map(social => {
              const { icon, name, url } = social;
              return (
                <li key={name} className="list-inline-item">
                  <a
                    href={url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="social-link rounded-circle text-white mr-3"
                    title={`${name} profile of Florian Gyger`}
                  >
                    <i className={icon}></i>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      {/* <section id="contact" className="map">
      <iframe
        width="100%"
        height="100%"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=Florian+Gyger+Software&amp;output=embed"
      ></iframe>
      <br />
      <small>
        <a href="https://maps.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=Florian+Gyger+Software&amp;t=m&amp;z=15&amp;iwloc=A"></a>
      </small>
    </section> */}
    </Layout>
  );
};

export default IndexPage;
